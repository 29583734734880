/* eslint-disable change-detection-strategy/on-push */
import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @ViewChild('iconContainer')
  container: ElementRef;

  setFocus(): void {
    this.container.nativeElement.focus();
  }
}
