import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModule } from '@app/app.module';
import { AppComponent } from './app.component';
import { TranslationBrowserModule } from './core/modules/translation/translation-browser.module';

@NgModule({
  imports: [AppModule, BrowserAnimationsModule, TranslationBrowserModule],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
