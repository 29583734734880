import { Routes } from '@angular/router';
import { AuthActivateGuard } from './core/guards';
import { NotFoundComponent } from './core/modules/not-found/not-found.component';
import { ROUTE_AUTHENTICATION_PATHS } from './pages/authentication/authentication.routes.paths';

export const routes: Routes = [
  { path: '404', component: NotFoundComponent },
  {
    path: '',
    pathMatch: 'full',
    canLoad: [AuthActivateGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', redirectTo: ROUTE_AUTHENTICATION_PATHS.landing },
];
