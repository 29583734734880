export const ROUTE_MAIN_PATHS = {
  channelDetails: '/channel-details',
  home: '/home',
  notFound: '/404',
  player: '/player',
  postWorkout: '/post-workout',
  profile: '/profile',
  search: '/search',
  settings: '/settings',
  workoutDetails: '/workout-details',
  trainer: '/trainer-details',
};
