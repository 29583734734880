/* eslint-disable change-detection-strategy/on-push */
import { Component } from '@angular/core';
import { VERSION } from '@environments/version';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  readonly appHash: string;
  readonly appVersion: string;

  constructor() {
    this.appHash = VERSION.hash;
    this.appVersion = VERSION.version;
  }
}
