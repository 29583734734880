import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SupportedLanguages } from '@skyfit/core-data-library';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class TranslationBrowserModule {
  constructor(translate: TranslateService) {
    const languages = Object.values(SupportedLanguages);
    translate.addLangs(languages);
    const browserLang = translate?.getBrowserLang() || 'en';
    translate.use(browserLang);
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
