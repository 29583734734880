<div class="confirmation-dialog-container">
  <div class="confirmation-dialog-body" #optionsBody>
    <div class="confirmation-dialog-message">
      {{ messageText }}
    </div>
    <div class="confirmation-dialog-actions" id="optionsBody">
      <button
        #negativeButton
        id="negative"
        mat-button
        class="button-primary cancel-workout-button"
        (keydown.enter)="onClose()"
      >
        {{ negativeButtonText }}
      </button>
      <button
        #affirmativeButton
        id="affirmative"
        mat-button
        class="button-primary end-workout-button"
        (keydown.enter)="onConfirm()"
      >
        {{ affirmativeButtonText }}
      </button>
    </div>
  </div>
</div>
