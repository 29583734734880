import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppBrowserModule } from '@app/app.browser.module';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from './environments/_current/environment';

Sentry.init({
  dsn: environment.sentryDsn,
  environment: environment.serverEnvironment,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0,
  tracePropagationTargets: ['localhost', environment.aaptivServiceUrl],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.productionMode) {
  enableProdMode();
}

const dataLayer: any[] = [];
const initializeGoogleAnalytics = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsTrackingId}`;
  script.onload = () => {
    function gtag(command: string, config: string) {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }
    gtag('js', 'gtag/js');
    gtag('config', environment.googleAnalyticsTrackingId);
  };
  document.head.appendChild(script);
};

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppBrowserModule)
    .catch((err) => console.error(err));
}

if (document?.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

initializeGoogleAnalytics();
