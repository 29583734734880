import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AppStateService {
  hasResumed$: Subject<void>;
  isPaused$: Subject<void>;

  constructor() {
    this.hasResumed$ = new Subject<void>();
    this.isPaused$ = new Subject<void>();
  }

  onAppPause(): void {
    this.isPaused$.next();
  }

  onAppResume(): void {
    this.hasResumed$.next();
  }
}
