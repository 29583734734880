import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '../core/modules/nav-bar/nav-bar.module';
import { PAGES_COMPONENTS } from './pages.components';
import { PAGES_ROUTES } from './pages.routes';

@NgModule({
  declarations: [...PAGES_COMPONENTS],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule.forChild(PAGES_ROUTES),
  ],
  exports: [RouterModule],
})
export class PagesModule {}
