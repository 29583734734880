import { Routes } from '@angular/router';
import { AuthActivateGuard } from '@app/core/guards';
import { PagesComponent } from './pages.component';

export const PAGES_ROUTES: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthActivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () =>
          import('./workout-home/workout-home.module').then(
            (m) => m.WorkoutHomeModule
          ),
      },
      {
        path: 'profile',
        pathMatch: 'full',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'search',
        pathMatch: 'full',
        loadChildren: () =>
          import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'settings',
        pathMatch: 'full',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'channel-details/:type/:id',
        loadChildren: () =>
          import('./channel-details/channel-details.module').then(
            (m) => m.ChannelDetailsModule
          ),
      },
      {
        path: 'workout-details/:id/:origin',
        loadChildren: () =>
          import('./workout-details/workout-details.module').then(
            (m) => m.WorkoutDetailsModule
          ),
      },
      {
        path: 'player/:id/:mix/:origin',
        pathMatch: 'full',
        canActivateChild: [AuthActivateGuard],
        loadChildren: () =>
          import('./player/player.module').then((m) => m.PlayerModule),
      },
      {
        path: 'post-workout',
        pathMatch: 'full',
        canActivateChild: [AuthActivateGuard],
        loadChildren: () =>
          import('./post-workout/post-workout.module').then(
            (m) => m.PostWorkoutModule
          ),
      },
    ],
  },
];
