import { Routes } from '@angular/router';
import { CanDeactivateGuard } from 'src/services/page-navigation/can-deactivate.guard';
import { ROUTE_AUTHENTICATION_PATHS } from './authentication.routes.paths';
import { LandingComponent } from './landing/landing.component';
import { SignInComponent } from './sign-in/sign-in.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ROUTE_AUTHENTICATION_PATHS.landing,
        component: LandingComponent,
        data: { title: '' },
      },
      {
        path: ROUTE_AUTHENTICATION_PATHS.login,
        component: SignInComponent,
        data: { title: '' },
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];
