/* eslint-disable change-detection-strategy/on-push */
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_AUTHENTICATION_PATHS } from '../authentication.routes.paths';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
})
export class LandingComponent implements AfterViewInit {
  @ViewChild('signInButton', { read: ElementRef }) signInButton: ElementRef;
  buttonClasses = ['sign-in-button'];

  constructor(private _router: Router) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.signInButton.nativeElement.focus();
    }, 100);
  }

  onNavigateSignIn(): void {
    this._router.navigateByUrl(ROUTE_AUTHENTICATION_PATHS.login);
  }
}
