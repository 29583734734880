/* eslint-disable change-detection-strategy/on-push */
import { Component } from '@angular/core';

@Component({
  selector: 'app-pages',
  template: `
    <app-nav-bar>
      <router-outlet></router-outlet>
    </app-nav-bar>
  `,
})
export class PagesComponent {}
