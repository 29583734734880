import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class PageNavigationService {
  isInContent$: Subject<boolean>;
  // you're either in content or nav...content manages sub states for now

  private _visible = true;

  constructor() {
    this.isInContent$ = new Subject<boolean>();
  }

  get showNav(): boolean {
    return this._visible;
  }

  setNavVisible(visible: boolean): void {
    this._visible = visible;
  }

  goToContent(): void {
    this.isInContent$.next(true);
  }

  goToNav(): void {
    this.isInContent$.next(false);
  }
}
