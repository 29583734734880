/* eslint-disable @typescript-eslint/naming-convention */
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { routes } from './app-routing.routes';
import { AppComponent } from './app.component';
import { APP_PROVIDERS } from './app.providers';
import { ConfirmationDialogModule } from './core/modules/confirmation-dialog/confirmation-dialog.module';
import { NotFoundComponent } from './core/modules/not-found/not-found.component';
import { SplashComponent } from './core/modules/splash/splash.component';
import { AuthenticationModule } from './pages/authentication/authentication.module';
import { PagesModule } from './pages/pages.module';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, '../assets/i18n/', '.json');

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent, SplashComponent],
  imports: [
    BrowserModule,
    PagesModule,
    AuthenticationModule,
    CommonModule,
    ConfirmationDialogModule,
    MatDialogModule,
    OverlayModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
    }),
    TranslateModule,
    IonicModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [...APP_PROVIDERS],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
