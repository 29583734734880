/* eslint-disable @typescript-eslint/dot-notation */
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { IconComponent } from '@app/core/modules/icon/icon.component';
import { ROUTE_MAIN_PATHS } from '@app/pages/pages.routes.paths';
import { Subscription } from 'rxjs';
import { PageNavigationService } from 'src/services/page-navigation/page-navigation.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent
  implements OnInit, AfterContentChecked, AfterViewInit
{
  @ViewChildren(IconComponent)
  navIcons: QueryList<IconComponent>;
  @ViewChildren(IconComponent)
  navIconsElRef: QueryList<ElementRef>;
  @ViewChildren('home, profile, search') elements: QueryList<RouterLinkActive>;

  activeIndex: number;
  nodeHasBeenFocused = false;
  currentNodeIndex: number;

  imageUrl = '../../../../../assets/img/aaptiv-logo-white.svg';

  // routerLinks
  homeRouterLink = ROUTE_MAIN_PATHS.home;
  profileRouterLink = ROUTE_MAIN_PATHS.profile;
  settingsRouterLink = ROUTE_MAIN_PATHS.settings;
  searchRouterLink = ROUTE_MAIN_PATHS.search;

  private readonly _subscriptions: Subscription;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _router: Router,
    private _pageNavigationService: PageNavigationService
  ) {
    this._subscriptions = new Subscription();
  }

  get visible(): boolean {
    return this._pageNavigationService.showNav;
  }
  //Switch tabindex to 0 if trying to focus in navigation. Prevents nav from stealing focus onInit
  ngOnInit(): void {
    this._subscriptions.add(
      this._pageNavigationService.isInContent$.subscribe((isInContent) => {
        if (!isInContent) {
          this.navIconsElRef.forEach((ref) => {
            ref['container'].nativeElement.setAttribute('tabindex', 0);
          });
          this.setDefaultFocus();
        }
      })
    );
  }

  ngAfterContentChecked(): void {
    //stackoverflow.com/a/43513959
    this._changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.setActiveIcon();
  }

  onUpdateIndex(index: number): void {
    this.currentNodeIndex = index;
    this._changeDetector.detectChanges();
  }

  onSelectPage(event: any, route: string): void {
    event?.preventDefault();
    this._router.navigate([route]);
  }

  onRightEdgeMovement(index: number): void {
    this.currentNodeIndex = null;
    this._pageNavigationService.goToContent();
  }

  setActiveIcon() {
    this.activeIndex = null;
    this.elements.forEach((res, index) => {
      if (res.isActive) {
        this.activeIndex = index;
      }
    });
  }

  setDefaultFocus(): void {
    let currentIndex = 0;
    if (this.activeIndex === 0) {
      currentIndex++;
    }
    this.currentNodeIndex = currentIndex;
    this.navIcons?.get(currentIndex)?.setFocus();
  }
}
