import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { GridModule } from '@app/core/modules/grid/grid.module';
import { IconModule } from '@app/core/modules/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { NAVIGATION_COMPONENTS } from './nav-bar.module.components';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    IconModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [...NAVIGATION_COMPONENTS],
  exports: [...NAVIGATION_COMPONENTS],
})
export class NavigationModule {}
