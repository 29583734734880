// https://javascript.plainenglish.io/creating-a-splash-screen-in-angular-for-loading-all-the-data-at-startup-b0b91d9d9f93
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
@Injectable()
export class SplashScreenStateService {
  subject = new Subject();
  subscribe(onNext): Subscription {
    return this.subject.subscribe(onNext);
  }
  stop(): void {
    // no need for a start method, spalsh screen should be the first thing that fires
    this.subject.next(false);
  }
}
