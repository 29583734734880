<div class="landing-container">
  <div class="actions-column">
    <img
      class="aaptiv-landing-logo"
      src="assets/img/aaptiv-logo.svg"
      alt="Aaptiv logo"
    />
    <button
      #signInButton
      mat-button
      class="button-primary sign-in-button"
      (click)="onNavigateSignIn()"
    >
      {{ 'auth.signIn' | translate }}
    </button>
  </div>
  <div class="image-column"></div>
</div>
